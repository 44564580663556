@font-face {
  font-family: LabGrotesqueK;
  font-style: normal;
  font-weight: 900;
  src: url("./assets/fonts/LabGrotesqueK-Black.ttf");
  font-display: swap;
}

@font-face {
  font-family: LabGrotesqueK;
  font-style: normal;
  font-weight: 700;
  src: url("./assets/fonts/LabGrotesqueK-Bold.ttf");
  font-display: swap;
}

@font-face {
  font-family: LabGrotesqueK;
  font-style: normal;
  font-weight: 500;
  src: url("./assets/fonts/LabGrotesqueK-Medium.ttf");
  font-display: swap;
}

@font-face {
  font-family: LabGrotesqueK;
  font-weight: 400;
  src: url("./assets/fonts/LabGrotesqueK-Regular.ttf");
  font-display: swap;
}

@font-face {
  font-family: LabGrotesqueK;
  font-weight: 300;
  src: url("./assets/fonts/LabGrotesqueK-Light.ttf");
  font-display: swap;
}

@font-face {
  font-family: LabGrotesqueK;
  font-weight: 100;
  src: url("./assets/fonts/LabGrotesqueK-Thin.ttf");
  font-display: swap;
}

html,
body,
#root {
  height: 100%;
  font-family: LabGrotesqueK, sans-serif;
  color: var(--black);
}

body {
  max-width: 1440px;
  padding: 0;
  margin: 0 auto;
  background-color: var(--gray);
}

*:focus {
  outline: none;
}

*:focus-visible {
  /* outline: var(--black) auto 5px; */
}

h1,
h2,
h3,
h4,
h5,
p {
  user-select: none;
}

h1 {
  margin: 32px 0;
  font: 700 40px/48px LabGrotesqueK, sans-serif;
}

h2 {
  margin: 32px 0;
  font: 700 32px/40px LabGrotesqueK, sans-serif;
}

h3 {
  margin: 8px 0;
  font: 700 28px/36px LabGrotesqueK, sans-serif;
}

h4 {
  margin: 8px 0;
  font: 700 20px/28px LabGrotesqueK, sans-serif;
}

p {
  font: 400 16px/22px LabGrotesqueK, sans-serif;
}

a {
  color: var(--links);
}

.wideCard + .wideCard {
  margin-top: 20px;
}

:root {
  --btnBorderRadiusLarge: "8px";
  --white: #fff;
  --black: #222;
  --inactive-black: #808080;
  --gray: #f6f6f6;
  --authority: #edeae5;
  --lightgray: #f2f2f2;
  --blue: #015ccb;
  --red: #f03b36;
  --success: #d7f8ae;
  --lite-gray: #f0f0f0;
  --warning: #fff0bc;
  --secondary: #808080;
  --secondary-text: #858585;
  --secondary-new: #d6d6d6;
  --n-focus-shadow:
    0 0 1px 3px rgba(0, 138, 255, 0.15),
    inset 0 0 0 1px #015ccb,
    inset 0 1px 2px 0 rgba(var(--n-rgb-black), 0.3);
  --default-text: 400 16px/20px LabGrotesqueK, sans-serif;
  --small-text: 400 14px/20px LabGrotesqueK, sans-serif;
  --thin-normal-text: 300 16px/22px LabGrotesqueK, sans-serif;
  --status-text: 400 16px/22px LabGrotesqueK, sans-serif;
  --border-radius-default: 8px;
  --links: #015ccb;
  --program-shadow: 0 0 40px rgba(0, 0, 0, 0.24);
}

.text-highlight{
  background-color: transparent;
  font-weight: 700;
  color: inherit;
}
